import { React, useState, useEffect } from "react"
import './App.css';

// the lat/lon for the "center" of NA

async function fetchData(url) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error.message);
        return [];
    }
}

function MyLeaderboard() {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    fetchData('https://grt-monkeytype-leaderboard.calderwhite1.workers.dev').then(data => {
      setLeaderboard(data)
    })
  }, []);

  const listItems = leaderboard
    .filter(item => item && item.name && item.username && item.wpm)
    .sort((a, b) => b.wpm - a.wpm)
    .map(({name, username, wpm}) => (
    <tr>
      <td>{name}</td>
      <td>{username}</td>
      <td>{wpm}</td>
    </tr>
  ));

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>WPM best (30s)</th>
          </tr>
        </thead>
        <tbody>
          {listItems} 
        </tbody>
      </table>
    </>
  );
}

export default MyLeaderboard;
